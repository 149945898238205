import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { EmailVerifyData, GoogleData, LoginData, LoginResponse, PasswordData, ResetPasswordData, ResetResponse, UserData, UserImagesData, UserProfileData } from './login/models/login';
import { BaseResponse, ImgResponse } from './shared/models/base.models';
import { InterestResp, userInterest } from './shared/models/interest.models';
import { CityResponse, CountryResponse, LanguagesResponse, UserLikeData, UserLikeResponse, UserProfileResponse, UsersResponse, UserStatisticsResponse } from './shared/models/profile.models';
import { CreateProjectResponse, FiltersProject, ProjectData, ProjectInfoResponse, ProjectResponse } from './shared/models/projects';
import { CategoryReportResponse, CategoryResponse, SendReportI, SubcategoryReportResponse } from './shared/models/reports.models';
import { FiltersUsers, SearchUsersResponse } from './shared/models/users';
import { GiftsResponse, SendGiftI } from './shared/models/fees.models';
import { DepositResp, TransactionResponse } from './shared/models/wallet';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly httpService: HttpClient) {}

  login(loginData: LoginData) {
    return this.httpService.post<LoginResponse>(`${environment.URL}users/login`, loginData);
  }

  getCodeToEmail(email: string) {
    return this.httpService.get<ResetResponse>(`${environment.URL}users/password/reset?email=${email}`);
  }

  resetPassword(resetPassword: ResetPasswordData) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/password/reset`, resetPassword);
  }

  signup(emailForm: string) {
    return this.httpService.post<ResetResponse>(`${environment.URL}users/signup`, {email: emailForm});
  }

  emailVerify(emailVerify: EmailVerifyData) {
    return this.httpService.post<LoginResponse>(`${environment.URL}users/email/verify`, emailVerify);
  }

  updatePassword(updatePassword: PasswordData) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/password`, updatePassword);
  }

  updateUser(userData: UserData) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users`, userData);
  }

  uploadImg(file: File, type: string) {
    return this.httpService.post<ImgResponse>(`${environment.URL}media/images?type=${type}`, file);
  }

  updateUserImages(images: UserImagesData[]) {
    return this.httpService.put<BaseResponse>(`${environment.URL}users/images`, {images: images});
  }

  updateUserProfile(userProfile: UserProfileData) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/profile`, userProfile);
  }

  loginGoogle(googleData: GoogleData) {
    return this.httpService.post<LoginResponse>(`${environment.URL}users/login/google`, googleData);
  }

  getAllInterest() {
    return this.httpService.get<InterestResp>(`${environment.URL}interests`);
  }

  setUserInterest(interests: userInterest[]) {
    return this.httpService.put<BaseResponse>(`${environment.URL}users/interests`, {interests: interests});
  }

  searchCities(city: string) {
    return this.httpService.get<CityResponse>(`${environment.URL}cities?q=${city}`);
  }

  searchCountry(country: string) {
    return this.httpService.get<CountryResponse>(`${environment.URL}countries?q=${country}`);
  }

  getAllLanguage() {
    return this.httpService.get<LanguagesResponse>(`${environment.URL}languages`);
  }

  getUserProfile() {
    return this.httpService.get<UserProfileResponse>(`${environment.URL}users/profile`);
  }

  getProjects(date: number, filtersProject: FiltersProject) {
    return this.httpService.get<ProjectResponse>(`${environment.URL}users/projects?created_at_min=${date}${filtersProject.distance > 0 ? `&distance=${filtersProject.distance}` : ""}${filtersProject.priceMin > 0 ? `&price_min=${filtersProject.priceMin}` : ""}${filtersProject.priceMax > 0 ? `&price_max=${filtersProject.priceMax}` : ""}${filtersProject.ratingMin > 0 ? `&rating_min=${filtersProject.ratingMin}` : ""}${filtersProject.ratingMax > 0 ? `&rating_max=${filtersProject.ratingMax}` : ""}${filtersProject.specialty ? `&interests=${filtersProject.specialty}` : ""}&limit=9999999&offset=0`);
  }

  createProject(projectData: ProjectData) {
    return this.httpService.post<CreateProjectResponse>(`${environment.URL}users/projects`, projectData);
  }

  getCategories() {
    return this.httpService.get<CategoryResponse>(`${environment.URL}categories`);
  }

  searchUsers(query: string, filters: FiltersUsers) {
    return this.httpService.get<SearchUsersResponse>(`${environment.URL}users/search?q=${query}&limit=10&offset=0${filters.categoryId ? `&category_id=${filters.categoryId}`: ""}${filters.gender !== "all" ? `&gender=${filters.gender}` :""}${filters.ageMin && filters.ageMin > 18 ? `&age_min=${filters.ageMin}` : "" }${filters.ageMax && filters.ageMax < 80 ? `&age_max=${filters.ageMax}` : ""}${(filters.distance && filters.distance > 0) ? `&distance=${filters.distance}`: ""}&rating_min=${filters.ratingMin}&rating_max=${filters.ratingMax}`);
  }

  searchCountries(query: string) {
    return this.httpService.get<CountryResponse>(`${environment.URL}countries?q=${query}`);
  }

  getUsersForYou(offset: number, limit: number) {
    return this.httpService.get<UsersResponse>(`${environment.URL}users?limit=${limit}&offset=${offset}`);
  }

  likeUser(likeData: UserLikeData) {
    return this.httpService.post<UserLikeResponse>(`${environment.URL}users/likes`, likeData);
  }

  getUserStatistic(userId: number) {
    return this.httpService.get<UserStatisticsResponse>(`${environment.URL}users/${userId}/statistics`);
  }

  followUser(userId: number) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/follows`, {target_user_id: userId});
  }

  getAllGift() {
    return this.httpService.get<GiftsResponse>(`${environment.URL}gifts`);
  }

  sendGift(sendGift: SendGiftI) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/gifts`, sendGift);
  }

  getReportCategory() {
    return this.httpService.get<CategoryReportResponse>(`${environment.URL}reports/categories`);
  }

  getReportSubcategory(categoryId: number) {
    return this.httpService.get<SubcategoryReportResponse>(`${environment.URL}reports/categories/${categoryId}/subcategories`);
  }

  sendReport(sendReport: SendReportI) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/reports`, sendReport);
  }

  getProjectsInfo(userId: number) {
    return this.httpService.get<ProjectInfoResponse>(`${environment.URL}users/${userId}/projects`);
  }

  getProjectInfoById(projectId: number) {
    return this.httpService.get<CreateProjectResponse>(`${environment.URL}users/projects/${projectId}`);
  }

  createProjectOffer(project_id: number, targetUserId: number) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/projects/${project_id}/offers`, {target_user_id: targetUserId});
  }

  finishProject(project_id: number, confirmed: boolean) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/projects/${project_id}/confirm`, {confirmed: confirmed});
  }

  cancelProject(project_id: number) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/projects/${project_id}/cancel`, {});
  }

  acceptProject(project_id: number, offer_id: number) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/projects/${project_id}/offers/${offer_id}/accept`, {});
  }

  declineProject(project_id: number, offer_id: number) {
    return this.httpService.patch<BaseResponse>(`${environment.URL}users/projects/${project_id}/offers/${offer_id}/decline`, {});
  }

  sendReview(target_user_id: number, rating: number, text: string, gift_id: number, project_id: number, appointment_id?: number) {
    return this.httpService.post<BaseResponse>(`${environment.URL}users/reviews`, {target_user_id: target_user_id, rating: rating, text: text, gift_id: gift_id, appointment_id: appointment_id, project_id: project_id});
  }

  depositToUserBalance(amount: number) {
    return this.httpService.post<DepositResp>(`${environment.URL}users/balance/deposit`, {amount: amount});
  }

  withdraw(amount: number, destination: string) {
    return this.httpService.post(`${environment.URL}users/balance/withdraw`, {amount: amount, destination:destination});
  }

  getTransaction(type:number) {
    return this.httpService.get<TransactionResponse>(`${environment.URL}users/balance/transactions?limit=9999&offset=0&tx_type=${type}`);
  }
}
